import React from 'react'
import Link from 'gatsby-link'
import { graphql } from "gatsby"
import * as utils from '../../utils'
import Layout from "../../components/layout"

export const query = graphql`
  query VideosQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {frontmatter: {type:{glob: "videorelease"}}}) {
      edges {
        node {
          id
          fields {
            slug
            title
          }
          frontmatter {
            nid
            vid
            type
            date
            year
            cover_filepath
            image_filepath
            status
            official
            timestamp
          }
          excerpt
        }
      }
    }
  }
`

export default ({data}) => {
  var sortedreleases = data.allMarkdownRemark.edges
    .filter(t => t.node && t.node.fields && t.node.fields.slug)
    .filter(t => t.node && t.node.frontmatter && t.node.frontmatter.status === 1)
    .filter(t => t.node && t.node.frontmatter && t.node.frontmatter.date)
    .map(t => ({...t, dt: t.node.frontmatter.date, timestamp: Date.parse(t.node.frontmatter.date)}))
    .sort((a, b) => b.timestamp - a.timestamp);

  return <Layout>
    <div className="contentheader">
      <h1>Our videos {data && data.site.siteMetadata.title}</h1>
      <p>Videos we've made or been a part of</p>
    </div>
    <div className="covers">
    {
      sortedreleases.map(t => {
        let p = t.node.frontmatter.cover_filepath || t.node.frontmatter.image_filepath
        return p ? <span key={t.node.id} className="cover">
          <Link to={'/' + t.node.fields.slug}>
            <img src={utils.getImageURL(p, 300)} alt={t.node.fields.title} width="150" height="150" />
          </Link>
        </span> : null
      })
    }
    </div>
  </Layout>;
}
